* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

.rbt-aux {
  display: none !important;
}
