@use "sass:math";

// Colors
.text-dark {
  color: $dark !important;
}

// Text cases
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

$weights: (400, 600, 500, 700);

@each $weight in $weights {
  .font-weight-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

@for $i from 10 through 50 {
  .text-size-#{$i} {
    font-size: math.div($i, 16) * 1rem !important;
  }
  .line-height-#{$i} {
    line-height: math.div($i, 16) * 1rem !important;
  }
}
