* {
  font-family: "br_omega", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark;
  font-weight: bold;
}

body {
  color: $dark;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
}

a {
  font-weight: 500;
  font-size: pxToRem(13);
  color: var(--brand-color);
  text-decoration: none;
  display: "inline-block";
}
