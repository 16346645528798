@import "../../../../assets/scss/abstracts/functions";
@import "../../../../assets/scss/abstracts/variables";

$button_size_normal_height: 50px;
$button_size_normal_spacing: pxToRem(36);
$button_size_normal_font: pxToRem(14);
$button_size_medium_height: 40px;
$button_size_medium_spacing: pxToRem(33);
$button_size_medium_font: pxToRem(13);
$button_size_small_height: 30px;
$button_size_small_spacing: pxToRem(22);
$button_size_small_font: pxToRem(12);

.button {
  height: $button_size_normal_height;
  padding: 0 $button_size_normal_spacing;
  background-color: $dark;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: pxToRem(12.5);
  text-align: center;
  color: white;
  font-size: $button_size_normal_font;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 600;

  > div {
    overflow: unset !important;
  }

  &:hover {
    // opacity: 0.8;
    .icon {
      transform: translateX(3px);
    }
  }
  .icon {
    transition: all 0.3s;
    height: 25px !important;
    width: auto !important;
    margin-left: 18px !important;
  }
}

.button--medium {
  height: $button_size_medium_height;
  padding: 0 $button_size_medium_spacing;
  font-size: $button_size_medium_font;
  font-weight: 500;
  .icon {
    height: 20px !important;
    width: auto;
  }
}

.button--small {
  height: $button_size_small_height;
  padding: 0 $button_size_small_spacing;
  font-size: $button_size_small_font;
  font-weight: 500;
  .icon {
    height: 16px !important;
    width: auto;
  }
}

.button--disabled {
  background-color: rgba($color: $dark, $alpha: 0.1) !important;
  cursor: not-allowed;
}

.button--red {
  background-color: $red;
}

.button--white {
  background-color: white;
  color: $dark;
  border: 1px solid #e9eef4;
}

.button--bordered-dark {
  background-color: white;
  color: $dark;
  border: 1px solid $dark;
}

.button--brand {
  background-color: var(--brand-color);
}

.button--transparent {
  background-color: transparent;
  color: $dark;
  border: 1px solid rgba($color: $dark, $alpha: 0.07);
}

.button--transparent-no-border {
  background-color: transparent;
  color: $dark;
  border: 1px solid rgba($color: $dark, $alpha: 0);
}

.button--rounded {
  border-radius: $button_size_normal_height;
}

.button--full {
  width: 100%;
}
